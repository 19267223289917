import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { createStyles, StyleRules, WithStyles, withStyles } from '@material-ui/core/styles';

import Header from '../components/ConsultantHeader';
import Footer from '../components/Footer';
import Loading from '../components/Loading';
import Notification from '../components/Notification';
import { AppState } from '../reducers';
import { loadingEndAction } from '../reducers/loadingReducer';
import debug from '../utils/debug';

const styles = (): StyleRules =>
  createStyles({
    noConsultant: {
      display: 'flex',
      justifyContent: 'center',
      textAlign: 'center',
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.3)',
    },
    noConsultantItem: {
      margin: '1%',
    },
    container: {
      display: 'flex',
      flexDirection: 'row',
      height: '100%',
    },
    nav: {
      width: '210px',
      background: '#20232A',
      minHeight: '50rem',
    },
    content: {
      width: '1350px',
      margin: '0 auto',
    },
    menuStart: {
      margin: '2rem 0',
    },
    menuPending: {
      margin: '2rem 0',
    },
    menuEnd: {
      margin: '2rem 0',
    },
    menuButton: {
      margin: '0.5rem 0',
      color: '#3f424f',
      display: 'flex',
      justifyContent: 'left',
    },
  });

const ConsultantLayout = ({ classes, children }: Props): React.ReactElement => {
  debug('Load consultant layout');

  const isLoading = useSelector((state: AppState) => state.loading.isLoading);
  const { isVisible, severity, message } = useSelector((state: AppState) => state.notification);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadingEndAction());
  }, []);

  return (
    <>
      <Helmet>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
        />
        <link rel="stylesheet" href="https://fonts.loli.net/icon?family=Material+Icons" />
      </Helmet>
      <Header />
      <div className={classes.container}>
        <div className={classes.content}>{children}</div>
      </div>
      <Footer />
      {isLoading && <Loading />}
      {isVisible && <Notification isVisible={isVisible} severity={severity} message={message} />}
    </>
  );
};

export interface Props extends WithStyles<typeof styles> {
  children?: React.ReactElement | React.ReactElement[];
  verificationHide?: boolean;
}

export default withStyles(styles)(ConsultantLayout);
